<template>
  <div class="box_home">
    <!-- <swiper key="open"></swiper> -->
    <el-tabs v-model="curTab" @tab-click="handleTab" class="tab_list" id="tabs">
      <el-tab-pane v-for="item in tabs" :key="item.tab" :name="item.tab">
        <span slot="label"><i :class="item.icon"></i> {{ item.name }}</span>
      </el-tab-pane>
    </el-tabs>
    <template v-if="$store.state.mode == 'mobile'">
      <div class="form_wrap" v-if="curTab == 1 || curTab == 2">
        <div class="form_item">
          <el-input v-model="boxForm.minPrice" type="number" placeholder="价格最小值" @change="queryBoxList" />
          <span class="line">-</span>
          <el-input v-model="boxForm.maxPrice" type="number" placeholder="价格最大值" @change="queryBoxList" />
          <button class="range_btn" @click="queryBoxList">筛选</button>
        </div>
      </div>
    </template>
    <div class="form_wrap" v-if="curTab == 1 || curTab == 2">
      <div class="form_item">
        <span class="label">排序：</span>
        <el-select v-model="boxForm.orderByFie" placeholder="请选择" @change="queryBoxList">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>

      <div class="form_item" v-if="$store.state.mode == 'pc'">
        <span class="label">
          <price size="large"><span>范围：</span></price>
        </span>
        <el-col :span="11">
          <el-input v-model="boxForm.minPrice" type="number" placeholder="请输入最小值" @change="queryBoxList" />
        </el-col>
        <el-col class="line" :span="1">-</el-col>
        <el-col :span="11">
          <el-input v-model="boxForm.maxPrice" type="number" placeholder="请输入最大值" @change="queryBoxList" />
        </el-col>
      </div>
      <div class="form_item" v-show="curTab == 2">
        <span class="label">玩家ID：</span>
        <el-input :placeholder="`${isPc ? '请输入' : ''}玩家ID`" v-model="boxForm.owner" @change="queryBoxList">
          <i class="el-icon-search mobile-show" slot="suffix" @click="queryBoxList"></i>
        </el-input>
      </div>
      <div class="form_item">
        <span class="label">盲盒ID：</span>
        <el-input :placeholder="`${isPc ? '请输入' : ''}盲盒ID`" v-model="boxForm.boxId" @change="queryBoxList">
          <i class="el-icon-search mobile-show" slot="suffix" @click="queryBoxList"></i>
        </el-input>
      </div>
    </div>
    <div class="call" v-if="curTab == 1">
      <div class="typeBox">
        <div
          class="types"
          @click.stop="
            handleChangeType('', '盲盒类别');
          "
          :class="boxPage.boxTypeId == '' ? `num_select` : `num`"
        >
          全部
        </div>
        <div
          class="types"
          v-for="(item, i) in boxTypeList"
          :class="boxPage.boxTypeId == item.id ? `num_select` : `num`"
          :key="i"
          @click.stop="
            handleChangeType(item.id, item.name);
          "
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="new_box" v-if="curTab == 4">
      <div class="type">
        <h2><span>1</span>选择盲盒风格</h2>
        <div class="type_list">
          <div class="type_item" v-for="item in boxTypes" :key="'type' + item.id" @click="handleType(item)">
            <img :class="{ checked: item.id == curBoxType }" :src="item.url" alt="" />
          </div>
        </div>
      </div>
      <div class="name">
        <h2><span>2</span>为您的盲盒命名</h2>
        <input type="text" v-model="newBox.boxName" maxlength="20" placeholder="请输入您的盲盒名称" />
      </div>
      <div class="select">
        <div class="title">
          <h2><span>3</span>选择物品</h2>
          <button @click="handleAdd">添加物品+</button>
        </div>
        <div class="select_list">
          <div class="item" v-for="(item, index) in resultList" :key="item.id">
            <div class="img"><img :src="item.imageUrl" alt="" /></div>
            <p>{{ item.name }}</p>
            <price :price="item.usePrice" :size="$mode == 'pc' ? 'large' : 'small'" class="price" />
            <div class="odds_input">
              <input type="number" ref="input" placeholder="请输入概率" v-model="item.oddsResult" @blur="validateNumber($event, index)" onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" />
              <span class="odds_text">%</span>
            </div>
            <i class="icon el-icon-error" @click="handleDel(item)"></i>
          </div>
        </div>
      </div>
      <div class="summary">
        <p>
          总概率：<span>{{ totalPr || 0 }}%</span>
        </p>
        <p>
          剩余概率：<span>{{ totalPr ? setOdds(100, totalPr) : 0 }}%</span>
        </p>
        <p>
          盲盒价格：<span>{{ (newBox.expectBoxPrice || 0).toFixed(2) }}</span>
        </p>
        <p>
          开启奖励：<span>{{ rewardNum }}</span>
        </p>
        <button @click="handleCreate" :class="{ opacity: isOpacity }">创建盲盒</button>
      </div>
      <div class="tip_content">
        <p>*开启奖励：当其他玩家开启您创建的盲盒时，您将获得相应的游戏奖励。</p>
        <p>*盲盒价格：随着盲盒内游戏饰品价值变化而会相应改变，同时开启奖励也会随之改变。</p>
        <p>*概率范围：盲盒需添加4～30个任意物品数，每件物品可填写的概率范围为0.1～99.9</p>
      </div>
    </div>
    <div class="box_wrap" v-else>
      <div class="box_list">
        <boxCard v-for="(item, index) in boxList" :boxItem="item" :key="index" :isMe="curTab == 3" @update="delOffBox" />
      </div>
      <div v-if="!boxList.length" class="over_text pc-show">--暂无数据--</div>
      <div class="over_text mobile-show">{{ isOver }}</div>
      <el-pagination background class="pc-show" layout="prev, pager, next" :hide-on-single-page="true" :total="boxTotal" :current-page="boxPage.page" :page-size="boxPage.size" @current-change="queryBoxList" />
    </div>
    <el-dialog title="请选择物品" :modal-append-to-body="false" append-to-body class="select_dialog" :visible.sync="selectVisible" :close-on-click-modal="false" @close="handleCloseAdd">
      <div class="form_wrap" v-if="$store.state.mode == 'mobile'">
        <div class="form_item">
          <el-input v-model="selectForm.minPrice" type="number" placeholder="价格最小值" @change="getGoodsList(1)" />
          <span class="line">-</span>
          <el-input v-model="selectForm.maxPrice" type="number" placeholder="价格最大值" @change="getGoodsList(1)" />
          <button class="range_btn" @click="getGoodsList(1)">筛选</button>
        </div>
      </div>
      <div class="form_wrap">
        <div class="form_item">
          <span class="label">类别：</span>
          <el-select v-model="selectForm.type" placeholder="请选择" @change="getGoodsList(1)">
            <el-option v-for="(item, index) in categoryOptions" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="form_item">
          <span class="label">搜索：</span>
          <el-input :placeholder="`${isPc ? '请输入' : ''}物品名称`" v-model="selectForm.name" @change="getGoodsList(1)">
            <i class="el-icon-search mobile-show" slot="suffix" @click="getGoodsList(1)"></i>
          </el-input>
        </div>
        <div class="form_item" v-if="$store.state.mode == 'pc'">
          <span class="label">
            <price size="large"><span>范围：</span></price>
          </span>
          <el-col :span="11">
            <el-input v-model="selectForm.minPrice" type="number" placeholder="请输入最小值" @change="getGoodsList(1)" />
          </el-col>
          <el-col class="line" :span="1">-</el-col>
          <el-col :span="11">
            <el-input v-model="selectForm.maxPrice" type="number" placeholder="请输入最大值" @change="getGoodsList(1)" />
          </el-col>
        </div>
        <div class="form_item">
          <span class="label">排序：</span>
          <el-select v-model="selectForm.orderByFie" placeholder="请选择" @change="getGoodsList(1)">
            <el-option v-for="(item, index) in sortOptions" :key="'sort' + index" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
      <div class="goods_list" v-if="goodsList.length">
        <goodsCard  v-for="(item, index) in goodsList" :key="'goods' + index" :goodsItem="{ ...item, isSelect: selectIds.includes(item.id) }" :keyMap="{ price: 'usePrice' }" :isCheck="true" @handleClick="handleSelect" />
      </div>
      <div class="not_data" v-else>--暂无物品--</div>
      <div class="confirm_select">
        <el-pagination v-if="goodsTotal" background layout="prev, pager, next" :hide-on-single-page="true" :total="goodsTotal" :current-page="goodsPage.pageNum" :page-size="goodsPage.pageSize" @current-change="getGoodsList" />
        <div v-else></div>
        <div class="select_btn">
          <p>已选{{ selectList.length }}个</p>
          <button @click="handleUse">使用选择</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import swiper from '@/components/swiperUser.vue';
import goodsCard from '@/components/goodsCard.vue';
import { getUserInfo, ornamentsList, shopcondition, getArticle, getBoxList, fastCreateOwnBox, webResourceList, computeBoxPrice, getBoxTypeList } from '@/api/index';
import boxCard from '@/components/boxCard.vue';
import { throttle } from '@/utils/utils';
import Decimal from 'decimal.js';
import { Message } from 'element-ui';
export default {
  data() {
    return {
      curTab: '1',
      boxTypeList: [],
      boxTypes: [],
      selectForm: { orderByFie: '3', maxPrice: null, minPrice: null, type: '', name: '' },
      goodsPage: { pageSize: 18, pageNum: 1 },
      options: [
        { value: '5', label: '受欢迎程度' },
        { value: '1', label: '价格（从高到低）' },
        { value: '2', label: '价格（从低到高）' },
      ],
      sortOptions: [
        { value: '3', label: '价格（从低到高）' },
        { value: '4', label: '价格（从高到低）' },
      ],
      boxList: [],
      tabs: [
        { name: 'BEE的盲盒', icon: 'el-icon-s-home', tab: '1' },
        { name: '玩家的盲盒', icon: 'el-icon-s-platform', tab: '2' },
        { name: '我的盲盒', icon: 'el-icon-s-custom', tab: '3' },
        { name: '创建盲盒', icon: 'el-icon-circle-plus', tab: '4' },
      ],
      boxForm: {
        boxId: '',
        name: '',
        owner: '',
        orderByFie: '5',
        maxPrice: null,
        minPrice: null,
        gameMouldId: 1,
      },
      boxPage: {
        page: 1,
        size: 20,
        boxTypeId: '',
      },
      isOver: '上拉加载更多',
      newBox: {
        boxImg01: '',
        boxName: '',
        expectBoxPrice: 0,
        ornamentDetails: {},
      },
      curBoxType: '',
      selectVisible: false,
      categoryOptions: [],
      goodsTotal: 0,
      boxTotal: 0,
      goodsList: [],
      selectList: [],
      resultList: [],
      totalPr: 0,
      tabTop: 0,
      abs: false,
      scrollDom: null,
      userId: '',
      // boxTypeList: [],
      typePage: {
        page: 1,
        size: 10,
      },
    };
  },
  components: {  boxCard, goodsCard },
  created() {
    this.queryUserInfo();
    this.getGoodsList(1);
    this.getCategory();
  },
  mounted() {
    this.getTabsTop();
    this.getTypeList();
  },
  beforeDestroy() {
    if (!this.scrollDom) return;
    this.scrollDom.pxoveEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$store.state.mode': {
      handler(val) {
        if (val == 'pc' || this.curTab == 4) {
          if (!this.scrollDom) return;
          this.scrollDom.pxoveEventListener('scroll', this.handleScroll);
        } else {
          this.scrollDom = document.querySelector('#main');
          if (!this.scrollDom) return;
          this.getTabsTop();
          this.scrollDom.addEventListener('scroll', this.handleScroll);
        }
      },
      immediate: true,
    },
    '$route.query': {
      handler(val) {
        const tab = val?.tab || '1';
        this.curTab = tab;
        if (tab == 4) return;
        if (tab == 3) {
          this.boxPage.status = [0, 2];
          delete this.boxPage.isPlayerBox;
        } else {
          if (tab == 1) this.boxPage.isPlayerBox = 0;
          if (tab == 2) this.boxPage.isPlayerBox = 1;
          delete this.boxPage.status;
        }
        console.log('触发监听');

        this.queryBoxList(1);
        if (!this.abs || !this.scrollDom) return;
        this.scrollDom.scrollTo({ top: this.tabTop, behavior: 'smooth' });
      },
      immediate: true,
    },
    curTab: {
      handler(val) {
        if (val == 4) {
          this.getBoxImgList();
          if (!this.scrollDom) return;
          this.scrollDom.pxoveEventListener('scroll', this.handleScroll);
        } else {
          if (!this.scrollDom) return;
          this.scrollDom.addEventListener('scroll', this.handleScroll);
        }
      },
      immediate: true,
    },
    resultList: {
      handler(list) {
        let pr = 0;
        if (list.length) {
          let count = 0;
          list.forEach(item => {
            if (item.oddsResult) {
              count += +item.oddsResult;
              if (count > 100) {
                this.$message({
                  message: '总概率不能大于100%',
                  type: 'warning',
                });
                pr = 0;
              } else {
                // 解决小数丢失精度问题
                pr = Decimal(pr).add(Decimal(item.oddsResult));
              }
            }
          });
        }
        this.totalPr = Decimal(pr);
      },
      deep: true,
    },
    totalPr(val) {
      if (val == 100) {
        const detail = this.resultList.reduce((target, item) => {
          let num = Decimal(item.oddsResult || 0);
          target[item.id] = num.dividedBy(100).toString();
          return target;
        }, {});
        this.newBox.ornamentDetails = detail;
        if (this.resultList.length < 4 || this.resultList.length > 30) return;
        computeBoxPrice(detail).then(res => {
          this.newBox.expectBoxPrice = res?.data?.data || 0;
        });
      } else {
        this.newBox.expectBoxPrice = 0;
      }
    },
  },
  computed: {
    isPc() {
      return this.$store.state.mode == 'pc';
    },
    selectIds() {
      return this.selectList.map(item => item.id);
    },
    isOpacity() {
      if (this.resultList.length < 4 || this.resultList.length > 30) return false;
      const isTrim = this.resultList.every(item => item.oddsResult >= 0.1);
      if (!isTrim) return false;
      const { boxName, boxImg01 } = this.newBox;
      return boxName && boxImg01 && this.totalPr == 100;
    },
    rewardNum() {
      const ownBoxAward = this.$store.state.USER_INFO?.ownBoxAward || 0;
      const result = this.newBox.expectBoxPrice * ownBoxAward;
      return result.toFixed(2);
    },
  },
  methods: {
    handleChangeType(res, name) {
      this.boxPage.boxTypeId = res;
      this.boxPage.boxTypeName = name;
      this.boxPage.page = 1;
      this.isOver = "上拉加载更多";
      // this.boxList = this.boxList.filter((item) => {
      //   return item.boxNum != 0;
      // });
      this.queryBoxList();
      // this.istypeshow = false;
    },
    getTypeList() {
      if (this.boxTypeList.length == 0) {
        getBoxTypeList(this.typePage).then(res => {
          if (res.data.code == 200) {
            this.boxTypeList.push(...res.data.data.list);
            if (res.data.data.list.length == this.typePage.size) {
              this.typePage.page++;
              this.getTypeList();
            } else {
              this.getAll = true;
            }
          }
          console.log('玩法列表', this.boxTypeList);
        });
      }
    },
    queryUserInfo() {
      this.userId = this.$store.state.USER_INFO.userId;
      if (this.userId) return;
      getUserInfo().then(res => {
        this.userId = res?.data?.data?.userId;
        this.$store.commit('USER_INFO', res?.data?.data || {});
      });
    },
    getTabsTop() {
      let timer = setTimeout(() => {
        const tabs = document.querySelector('#tabs');
        if (!tabs) return;
        const headerH = 60;
        this.tabTop = tabs.getBoundingClientRect().top - headerH;
        clearTimeout(timer);
      }, 500);
    },
    // 剩余概率小数丢失精度处理
    setOdds(num1, num2) {
      return Decimal(num1).sub(Decimal(num2));
    },
    validateNumber(event, index) {
      const val = event.target.value.trim();
      if (!val) return;

      const value = val
        .replace(/(\.\d)\d*/, '$1') //保留两位小数
        .replace(/[^\d.]/g, '') // 清除"数字和."以外的字符
        .replace(/^\./g, '') // 验证第一个字符是数字
        .replace(/^0+\./g, '0.') //把小数0开头的多余0去掉
        .replace(/^[0]+/, ''); //把整数0开头的多余0去掉
      const result = parseFloat(value); //去除末尾的0
      this.resultList[index].oddsResult = result;
      this.$refs.input[index].value = result;
    },
    handleUse() {
      this.playAudio();
      this.handleCloseAdd();
      this.resultList = this.selectList.sort((a, b) => a.usePrice - b.usePrice);
    },
    handleSelect(item) {
      const index = this.selectList.findIndex(ite => ite.id == item.id);
      if (index !== -1) {
        this.selectList.splice(index, 1);
      } else {
        this.selectList.push(item);
      }
    },
    getBoxImgList() {
      webResourceList({ type: 2, page: 1, size: 10 }).then(res => {
        this.boxTypes = res?.data?.data.list;
      });
    },
    getGoodsList(page) {
      if (page && typeof page == 'number') this.goodsPage.pageNum = page;
      const params = { ...this.selectForm, ...this.goodsPage };
      ornamentsList(params).then(res => {
        const { list = [], total } = res?.data?.data || {};
        this.goodsList = [...list];
        this.goodsTotal = total;
      });
    },
    getCategory() {
      shopcondition(0).then(res => {
        let data = res?.data?.data || [];
        this.categoryOptions = data.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return { value, label: key };
        });
        this.categoryOptions.push({ value: '', label: '默认' });
      });
    },
    handleCreate() {
      if (this.resultList.length < 4) {
        Message({ message: '至少选择四种饰品', type: 'warning' });
      }
      if (!this.isOpacity) return;
      this.$confirm('是否提交审核？', '', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        confirmButtonClass: 'active_btn',
        center: true,
      })
        .then(() => {
          fastCreateOwnBox(this.newBox).then(res => {
            if (res?.data?.code != 200) {
              this.$message({ message: res.data.msg, type: 'warning' });
              return;
            }
            this.$router.replace('/open?tab=3');
            this.handleReset();
          });
        })
        .catch(() => {
          this.playAudio();
        });
    },
    handleReset() {
      this.totalPr = 0;
      this.curBoxType = '';
      this.resultList = [];
      this.selectList = [];
      this.newBox = { boxImg01: '', boxName: '', expectBoxPrice: 0, ornamentDetails: {} };
    },
    handleDel(item) {
      this.selectList = this.selectList.filter(ite => ite.id != item.id);
      this.resultList = this.resultList.filter(ite => ite.id != item.id);
    },
    handleAdd() {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.selectVisible = true;
      if (this.goodsList.length) return;
      this.getGoodsList(1);
    },
    handleCloseAdd() {
      this.playAudio();
      this.selectVisible = false;
      this.selectForm = { orderByFie: '3', maxPrice: null, minPrice: null, type: '', name: '' };
    },
    handleType(item) {
      this.playAudio();
      this.newBox.boxImg01 = item.url;
      this.curBoxType = item.id;
    },
    handleTab({ name }) {
      this.playAudio();
      this.$router.replace(`/open?tab=${name}`);
    },
    handleScroll: throttle(function (e) {
      if (this.$store.state.mode == 'pc') return;
      this.abs = e.target.scrollTop > this.tabTop;
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.isOver == '上拉加载更多') {
          this.boxPage.page++;
          this.queryBoxList();
        }
      }
    }, 100),
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleilltate() {
      this.$bus.$emit('is_illustrate', true);
      let timer = setTimeout(() => {
        getArticle('wzmh').then(res => {
          let dream = res?.data?.data.contentList[0].content;
          this.$bus.$emit('nr_illustrate', { cont: dream });
          clearTimeout(timer);
        });
      }, 500);
    },
    handleGo(res) {
      this.$router.push({
        path: '/openbox',
        query: {
          id: res,
        },
      });
    },
    delOffBox(id) {
      if (this.$store.state.mode == 'pc') {
        this.queryBoxList();
      } else {
        const list = [...this.boxList];
        this.boxList = list.filter(item => item.boxId != id);
      }
    },
    queryBoxList(page) {
      if (!this.userId && this.curTab == 3) {
        setTimeout(() => {
          this.queryBoxList(page);
        }, 100);
        return false;
      }
      this.isOver = '加载中';
      if (page && typeof page == 'number') this.boxPage.page = page;
      const params = { ...this.boxForm, ...this.boxPage };
      params.owner = this.curTab == 3 ? this.userId : this.boxForm.owner;
      // console.log('canshu',this.curTab,this.userId);
      Object.keys(params).forEach(key => {
        if (params[key] === '' || params[key] === undefined || params[key] === null) delete params[key];
      });
      if (this.curTab == 3) {
        delete params.orderByFie;
        delete params.gameMouldId;
      }
      console.log('箱子参数', params);

      getBoxList(params).then(res => {
        if (res?.data?.code != 200) return;
        const { list, total } = res?.data?.data || {};
        list.map(item => (item.boxNum = 0));
        if (this.$store.state.mode == 'pc') {
          this.boxList = [...list];
          this.boxTotal = total || 0;
        } else {
          if (this.boxPage.page == 1) {
            this.boxList = [...list];
          } else {
            this.boxList.push(...list);
          }
          this.isOver = list?.length < this.boxPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.call {
  height: 60px;
  @media @max750 {
    height: 40px;
  }
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  color: white;
  box-sizing: border-box;
  justify-content: center;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0197d4;
    border-radius: 5px;
  }
  .typeBox {
    max-width: 100%;
    // overflow-x: scroll;
    display: flex;
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0197d4;
      border-radius: 5px;
    }
  }
  .num {
      
      &:hover {
        color: @main;
      }
    }
    .num_select {
      color: @main;
      border-color: @main !important;
    }
  // .num_select {
  //   background-image: url('../../assets/image/num_select.png');
  // }
  // .num {
  //   background-image: url('../../assets/image/num.png');
  // }
  .types {
    // border-radius: 24px;
    min-width: 106px;
    border: 2px solid transparent;
    box-sizing: border-box;
    // display: inline-block;
    font-size: 20px;
    // .sc(24px);
    @media @max750 {
      font-size: 16px;
      min-width: 60px;
    }
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
    background-size: 100% 100% !important;
  }
}
.box_home {
  overflow-x: hidden;
  .tab_list {
    width: 100%;
    background: @bg1;
    @media @max750 {
      left: 0;
      top: -1px;
      z-index: 9;
      position: sticky;
    }
  }
  .form_wrap {
    margin: 40px @pcSpace;
    @media @max750 {
      margin: 10px @mSpace;
    }
  }
  .box_wrap {
    overflow: hidden;
    margin: 40px @pcSpace 0;
    .box_list {
      .grid(5, 14px);
    }
    @media @max750 {
      margin: 10px 12px 0;
      .box_list {
        .grid(2, 6px);
      }
    }
    .el-pagination {
      margin-top: 40px;
      text-align: center;
    }
  }
  .new_box {
    margin: 40px @pcSpace 0;
    @media @max750 {
      margin: 12px;
    }
    & > div {
      padding: 14px;
      background: #0d1214;
      border-radius: 10px;
      margin-bottom: 10px;
      &.name {
        .flex-a-center;
        white-space: nowrap;
        justify-content: space-between;
        input {
          flex: 1;
          height: 44px;
          margin-left: 30%;
          .sc(18px, #fff);
          background: @bg2;
          padding: 0 10px;
          border-radius: 4px;
          display: block;
        }
        @media @max750 {
          .flex-column;
          align-items: flex-start;
          input {
            flex: auto;
            width: 100%;
            height: 38px;
            margin-left: 0;
            font-size: 14px;
            margin-top: 10px;
            box-sizing: border-box;
          }
        }
      }
      &.select {
        .title {
          .flex-a-center;
          justify-content: space-between;
          button {
            .sc(18px, #fff);
            .btn-active(140px, 36px);
            border-radius: 20px;
            @media @max750 {
              font-size: 14px;
              .btn-active(100px, 30px);
            }
          }
        }
        .select_list {
          @media @max750 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .item {
            margin-top: 20px;
            background: @bg1;
            padding: 4px 40px;
            border-radius: 10px;
            position: relative;
            .flex-a-center;
            @media @max750 {
              width: 48%;
              margin-top: 14px;
              padding: 10px 14px;
              box-sizing: border-box;
              .flex-column-center;
            }
            .img {
              .flex-center;
              .wh(100px, 100px);
              overflow: hidden;
              img {
                .wh(100%, auto);
              }
            }
            & > p {
              .ell;
              .sc(20px, #fff);
              flex: 2;
              margin: 0 40px;
              @media @max750 {
                margin: 0;
                flex: none;
                width: 100%;
                font-size: 14px;
                text-align: center;
              }
            }
            .price,
            .odds_input {
              flex: 1;
              height: 44px;
              background: @bg2;
              padding: 0 10px;
              margin-right: 20px;
              border-radius: 4px;
              .sc(18px, #fff);
              @media @max750 {
                height: 38px;
                margin-top: 4px;
                margin-right: 0;
                font-size: 14px;
              }
            }
            .odds_input {
              width: 100%;
              .flex-a-center;
              @media @max750 {
                padding: 0 6px;
              }
              input {
                width: 90%;
                height: 100%;
                display: block;
                background: none;
                .sc(16px, #fff);
                @media @max750 {
                  height: 38px;
                  font-size: 14px;
                }
              }
              .odds_text {
                padding-left: 10px;
              }
            }
            .icon {
              font-size: 26px;
            }
            @media @max750 {
              .price {
                background: none;
              }
              & > input {
                flex: none;
                width: 86%;
              }
              .icon {
                top: 6px;
                right: 6px;
                font-size: 20px;
                position: absolute;
              }
            }
          }
        }
      }
      &.summary {
        .flex-a-center;
        @media @max750 {
          flex-wrap: wrap;
          justify-content: center;
        }
        & > p {
          width: 15%;
          margin-right: 20px;
          .sc(20px, #fff);
          span {
            color: @main;
          }
          @media @max750 {
            width: 40%;
            margin: 0 auto;
            font-size: 14px;
            text-align: center;
          }
        }
        & > button {
          .sc(18px, #fff);
          .wh(160px, 50px);
          margin-left: auto;
          font-weight: bold;
          background: url(../../assets/image/loginbtn.png) no-repeat;
          background-size: 100% 100%;
          opacity: 0.5;
          @media @max750 {
            .wh(140px, 44px);
            margin-left: 0;
            margin-top: 10px;
            font-size: 16px;
          }
        }
        .opacity {
          opacity: 1;
        }
      }
      &.tip_content {
        & > p {
          .sc(18px, #fff);
          line-height: 26px;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          @media @max750 {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    h2 {
      .flex-a-center;
      .sc(20px, #fff);
      @media @max750 {
        font-size: 16px;
      }
      & > span {
        .wh(30px, 30px);
        .sc(18px, #fff);
        line-height: 30px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
        background: rgba(@main, 0.7);
        @media @max750 {
          .wh(20px, 20px);
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .type_list {
      margin-top: 20px;
      .flex-a-center;
      @media @max750 {
        margin-top: 10px;
      }
      .type_item {
        flex: 1;
        margin: 0 10px;
        position: relative;
        .flex-center;
        @media @max750 {
          margin: 0 2px;
        }
        img {
          .wh(100%, 100%);
          border-radius: 14px;
          transition: all 0.2s;
          border: 2px solid transparent;
          @media @max750 {
            border-radius: 10px;
          }
        }
        .checked {
          border-color: @main;
        }
      }
    }
  }
}
.select_dialog {
  .flex-center;
  /deep/ .el-dialog {
    width: 1260px;
    background: @bg1;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 10vh !important;
    border: 1px solid #404448;
    @media @max750 {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      margin: 0 !important;
      box-sizing: border-box;
      .flex-column;
    }
    .el-dialog__header {
      padding: 14px 0;
      text-align: center;
      background: #0d1214;
      .el-dialog__title {
        .sc(24px, #fff);
        @media @max750 {
          font-size: 18px;
        }
      }
      .el-dialog__headerbtn {
        top: 18px;
        right: 20px;
        @media @max750 {
          right: 12px;
        }
      }
    }

    .el-dialog__body {
      padding: 0;
      @media @max750 {
        flex: 1;
        overflow-y: auto;
        .flex-column;
      }
      .form_wrap {
        margin: 20px;
        @media @max750 {
          margin: 10px @mSpace 0;
        }
      }
      .goods_list {
        .grid(6, 10px);
        margin: 20px;
        max-height: 50vh;
        overflow: hidden;
        overflow-y: auto;
        @media @max750 {
          flex: 1;
          margin: 0;
          max-height: none;
          padding: 10px @mSpace;
          .grid(3, 6px);
        }
      }
      .not_data {
        flex: 1;
      }
      .confirm_select {
        height: 90px;
        padding: 0 20px;
        background: #0d1214;
        box-sizing: border-box;
        .flex-a-center;
        @media @max750 {
          height: 60px;
          padding: 0 14px 0 6px;
          border-top: 1px solid @bd1;
          justify-content: space-between;
        }
        .select_btn {
          .flex-center;
          margin-left: auto;
          @media @max750 {
            margin-left: 0;
          }
          & > p {
            margin-right: 20px;
            .sc(18px, #fff);
            @media @max750 {
              font-size: 14px;
              margin-right: 12px;
            }
          }
          & > button {
            .sc(18px, #fff);
            .btn-active(140px, 38px);
            border-radius: 22px;
            @media @max750 {
              font-size: 14px;
              .btn-active(100px, 30px);
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.confirm_select {
  .el-pagination .el-pager {
    display: none;
  }
}
</style>
